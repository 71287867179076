.iframe {
    border: none;
    overflow: hidden;
}

.button-primary {
    width: 100%;
    border-radius: 6px;
    border: 2px solid transparent;
    display: inline-block;
    text-align: center;
    font-family: Gotham A,Gotham B,Helvetica,Arial,sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
    justify-content: center;
    text-decoration: none;
    margin-bottom: 0.2rem;
    padding: calc((44px - 1.5rem)/2) 0.75rem;
    color: #fff;
    background-color: #d7153a;
    margin-left: 8px;
    margin-right: 8px;
}

.button-primary:active {
    opacity: 40%;
}

.button-container {
    display: flex;
    flex: 1;
    justifyContent: center;
    alignItems: center;
}
